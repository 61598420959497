import { NTACartItem } from '@/models/NTACartItem.js';
import moment from 'moment'
import Vue from 'vue'

export default {
    state: {
        _isCheckingCapacity: false,

        selfServiceToken: '',
        addressClass: '',
        companyDomain: '',

        deliveryTypes: [],
        selectedDeliveryType: '',
        questions: [],

        // Delivery address
        isFetchingDeliveryETA: false,
        deliveryPostNos: [],
        selectedDeliveryAddress: false,
        deliveryEtaData: false,
        deliveryEtaErrorMessage: false,

        // date time
        selectedDate: '',
        selectedTime: '',
        availaleDeliveryDates: [],
        availaleDeliveryDateTimes: {},
        availableDeliveryTimes: [], // will change when selectedDate changes

        // items
        selectedCategoryIndex: 0,
        categories: [],
        categoryProducts: [],
        cachedCategoryProducts: {},
        productBuilders: [],
        ingredients: [],

        // Cart 
        cartItems: [],
        totalPrice: 0,
        lastInsertedProductID: false,
        lastInsertedCartItemUUID: false,

        // user data
        username: '',
        userEmail: '',
        userPhoneNumber: '',
        userComment: '',
        customerDataFields: [],
        companyQuestionAnswers: {},


        // Order
        /**
         * Used to show loader in ui
         */
        isProcessingOrder: false,
        completedOrderData: false,
        orderData: false,
        didSendViaSms: false,
        didSendViaEmail: false,
        didApplyDiscountCode: false,

        // Error
        makeOrderErrorMessage: ''
    },
    getters: {
        // User information stuff
        isUserInformationFilled(state, getters) { 
            return state.username.length !== 0 && state.userEmail.length !== 0 && state.userPhoneNumber.length !== 0
        },
        getFormattedDateTimeSelection(state, getters) {
            if (state.selectedDate === '' || state.selectedTime === '') return ''

            const today = new Date(new Date().setHours(0, 0, 0, 0))
            const currentFormattedDate = moment(today).format('YYYY-MM-DD')

            const isToday = state.selectedDate === currentFormattedDate
            const isAsap = state.selectedTime === state.availableDeliveryTimes[0]

            let result = ''
            if (!isToday) {
                result += moment(state.selectedDate).format('DD/M') + ' '
            }
            if (isAsap && isToday) {
                result += `ASAP (${state.selectedTime})`
            } else {
                result += state.selectedTime
            }
            return result
        },
        cartPrice(state) {
            return state.cartItems.reduce((acc, item) => acc + item.totalPrice, 0)
        },
        deliveryPrice(state, getters) {
            if (state.selectedDeliveryType !== 'delivery' || !state.selectedDeliveryAddress || !state.deliveryEtaData) return 0

            if (state.deliveryEtaData.free_after_cart_price && getters.cartPrice > state.deliveryEtaData.free_after_cart_price) {
                return 0
            } else {
                return state.deliveryEtaData.price
            }
        },
        minimumCartPriceToOrder(state) {
            if (state.selectedDeliveryType === 'delivery' && state.selectedDeliveryAddress && state.deliveryEtaData) {
                try { 
                    return state.deliveryEtaData.minimum_cart_price ? parseFloat(state.deliveryEtaData.minimum_cart_price) : 0
                } catch(error) { 
                    return 0
                }
            } else {
                return 0
            }
        },
        getTotalPrice(state, getters) {
            return parseFloat(getters.cartPrice) + parseFloat(getters.deliveryPrice)
        },
    },
    mutations: {
        setUserInformation(state, data) {
            state.username = data.username
            state.userEmail = data.email
            state.userPhoneNumber = data.phoneNumber
            state.userComment = data.comment
            state.companyQuestionAnswers = data.companyQuestionAnswers
        },
        setSelectedDeliveryType(state, type) {
            state.selectedDeliveryType = type
        },
        setSelectedCategoryIndex(state, index) {
            state.selectedCategoryIndex = index
        },
        addItemToCart(state, productItem) {
            let product = productItem.product
            let quantity = parseInt(productItem.quantity)

            const itemIndex = state.cartItems.findIndex(item => item.isEqual(productItem))
            const itemAlreadyExist = itemIndex != -1
            if (itemAlreadyExist) {
                state.cartItems[itemIndex].appendData(productItem)
                state.lastInsertedCartItemUUID = state.cartItems[itemIndex].uuid
            } else {
                const cartItem = new NTACartItem({
                    product: product,
                    quantity: quantity,
                    variant: productItem.variant,
                    extraIngredients: productItem.extraIngredients,
                    ingredientToRemove: productItem.ingredientToRemove,
                    questionAnswers: productItem.questionAnswers
                })
                state.cartItems.push(cartItem)
                state.lastInsertedCartItemUUID = cartItem.uuid
            }
        },
        deleteItemInCart(state, index) {
            if (index < 0) return

            state.cartItems.splice(index, 1)
        },
        resetNtaData(state) {
            state.username = ''
            state.userEmail = ''
            state.userPhoneNumber = ''
            state.userComment = ''
            state.companyQuestionAnswers = {}

            state.cartItems = []
            state.totalPrice = 0
            state.lastInsertedProductID = false
            state.lastInsertedCartItemUUID = false

            state.makeOrderErrorMessage = ''
            state.didSendViaSms = false
            state.didSendViaEmail = false
        },
        setSelectedDeliveryAddress(state, address) {
            Vue.prototype.logger.debug('selected address=', address)
            state.selectedDeliveryAddress = address
        },
        resetDeliveryAddressData(state) {
            state.selectedDeliveryAddress = false
            state.isFetchingDeliveryETA = false
            state.deliveryEtaData = false
            state.deliveryEtaErrorMessage = false
        },
    },
    actions: {
        async getSelfServiceToken({state, getters}) {
            Vue.prototype.logger.debug('getting new self service token...')

            const url = getters.getRegisterSetting('external_order_remote_link')+"&json=1"


            let response = false
            let responseData = false
            try {
                response = await fetch(url)
                responseData = await response.json()
            } catch(e) {
                Vue.prototype.logger.error(e)
                state.selfServiceToken = ''
                return
            }

            if (response.ok && responseData.code.toLowerCase() === 'ok') {
                state.selfServiceToken = encodeURIComponent(responseData.selfservice_token)
            } else {
                Vue.prototype.logger.error('Failed to get session details, response=', response)
            }

        },
        async checkCapacity({commit, dispatch, state}) {
            if (state._isCheckingCapacity) {
                Vue.prototype.log.debug('ignore check capacity')
                return
            }
            state._isCheckingCapacity = true

            // console.log('store: check capacity...')

            if (state.selfServiceToken === '') {
                Vue.prototype.logger.warn('self service token is empty')
                return
            }

            const addressID = (state.selectedDeliveryType === 'delvery' 
                && state.selectedDeliveryAddress 
                && state.selectedDeliveryAddress.guestID
            ) ? state.selectedDeliveryAddress.guestID : ""

            const formData = new FormData()
            formData.append('address_class', state.addressClass)
            formData.append('address_id', addressID)
            formData.append('delivery_date', state.selectedDate)
            formData.append('delivery_time', state.selectedTime)
            formData.append('delivery_type', state.selectedDeliveryType)

            for (const index in state.cartItems) {
                const cartItem = state.cartItems[index]

                let jsonData = cartItem.getFormatedProduct()
                formData.append(`cart[${index}][type]`, jsonData.type)
                formData.append(`cart[${index}][product_id]`, jsonData.product_id)
                formData.append(`cart[${index}][quantity]`, jsonData.quantity)
                formData.append(`cart[${index}][alert]`, jsonData.alert)

                const variantID = cartItem.variant ? cartItem.variant.variant_id : 0
                formData.append(`cart[${index}][variant_id]`, variantID)

                for (const j in cartItem.formattedProductBuilder) {
                    for (const k in cartItem.formattedProductBuilder[j]) {
                        formData.append(`cart[${index}][product_builder][${j}][${k}]`, cartItem.formattedProductBuilder[j][k])
                    }
                }

                for (let i in cartItem.ingredientToRemove) {
                    formData.append(`cart[${index}][optout][${i}]`, cartItem.ingredientToRemove[i].id)
                }

                let extraIngredientsObject = {}
                for (let i in cartItem.extraIngredients) {
                    let ingredient = cartItem.extraIngredients[i]
                    extraIngredientsObject[ingredient.id] = ingredient.quantity
                    formData.append(`cart[cart_items][${i}][extra_ingredients][${ingredient.id}]`, ingredient.quantity)
                }
            }



            let domainParam = `selfservice_token=${state.selfServiceToken}`
            let cmdParam = 'cmd=check_capacity_with_cache'
            let url = `${process.env.VUE_APP_NEMTAKEAWAY_ENDPOINT}/api/v1/session.php?${domainParam}&${cmdParam}`

            let response = false
            let responseData = false
            try {
                response = await fetch(url, {
                    method: 'POST',
                    //headers: {
                    //    'Content-Type': 'application/json'
                    //},
                    body: formData
                })
                responseData = await response.json()
                state._isCheckingCapacity = false
            }
            catch (error) {
                Vue.prototype.logger.error('store: error fetching capacities', error)
                state._isCheckingCapacity = false
                return
            }

            if (!response || responseData.code.toLowerCase() != 'ok') {
                // console.log("store: response not ok, ", responseData)
                return
            }

            state.addressClass = responseData.address_lookup_class

            if (responseData.dates) {
                if (responseData.dates.length == 0 && state.cartItems.length > 0) {
                    if (state.lastInsertedCartItemUUID) {
                        const index = state.cartItems.findIndex(item => item.uuid == state.lastInsertedCartItemUUID)
                        commit('deleteItemInCart', index)
                    }
                }

                state.availaleDeliveryDates = []
                let selectedDateStillAvailable = false
                let selectedTimeStillAvailable = false

                for (const tempDate in responseData.dates) {
                    if (tempDate.length == 10) {
                        state.availaleDeliveryDates.push(tempDate)
                        selectedDateStillAvailable = selectedDateStillAvailable || state.selectedDate === tempDate

                        let times = []
                        for (const time of responseData.dates[tempDate]) {
                            const t = moment(time * 1000).format('HH:mm')
                            selectedTimeStillAvailable = selectedTimeStillAvailable || state.selectedTime === t

                            times.push(t)
                        }
                        state.availaleDeliveryDateTimes[tempDate] = times 
                    }
                }
                
                if (state.selectedDate === '' || state.selectedTime === '') {
                    dispatch('setSelectedDate', state.availaleDeliveryDates[0])
                } else if (!selectedDateStillAvailable || !selectedTimeStillAvailable) {
                    dispatch('setSelectedDate', state.availaleDeliveryDates[0])
                    state.cachedCategoryProducts = {}
                    dispatch('getCategories')
                    dispatch('loadCategoryProductList')
                }
            } else {
                state.availableDeliveryTimes = []
                state.availaleDeliveryDateTimes = {}
                state.availableDeliveryTimes = []
            }
        },
        async getSessionData({state}) {
            if (state.selfServiceToken === '') {
                console.warn('self service token is empty')
                return
            }

            let domainParam = `selfservice_token=${state.selfServiceToken}`
            let cmdParam = 'cmd=get_details'
            let addressClass = `address_class=${state.addressClass}`
            let url = `${process.env.VUE_APP_NEMTAKEAWAY_ENDPOINT}/api/v1/session.php?${domainParam}&${cmdParam}&${addressClass}`

            let response = await fetch(url)
            let responseData = await response.json()
            
            if (response.ok && responseData.code.toLowerCase() === 'ok') {
                state.addressClass = responseData.details.address_lookup_class
                state.deliveryTypes = responseData.details.order_delivery_type
                state.customerDataFields = responseData.details.customer_data_fields
                state.companyDomain = responseData.details.company_domain
                state.questions = responseData.details.questions
                state.deliveryPostNos = responseData.details.delivery_post_nos

                // setting default delivery type
                if (state.deliveryTypes.length > 0) {
                    state.selectedDeliveryType = state.deliveryTypes[0]
                }
            } else {
                Vue.prototype.logger.error('Failed to get session details, response=', response)
            }
        },
        async getCategories({state}) {
            if (state.selfServiceToken === '') {
                console.warn('self service token is empty')
                return
            }
            let domainParam = `selfservice_token=${state.selfServiceToken}`
            let cmdParam = 'cmd=get_categories_list'
            let params = 'get_products=true'
            let url = `${process.env.VUE_APP_NEMTAKEAWAY_ENDPOINT}/api/v1/products.php?${domainParam}&${cmdParam}&${params}`

            if (state.selectedDate != '' && state.selectedTime != '') {
                const encodedDateTime = encodeURIComponent(`${state.selectedDate} ${state.selectedTime}`)
                url += `&datetime=${encodedDateTime}`
            }

            let response = await fetch(url)
            let responseData = await response.json()

            if (response.ok && responseData.code.toLowerCase() === 'ok') {

                state.productBuilders = responseData.product_builders
                state.ingredients = responseData.ingredients

                const fetchedCategories = responseData.categories
                const filteredCategories = []
                state.categories = []

                for (const category of fetchedCategories) {
                    const categoryProducts = category['products']
                    state.cachedCategoryProducts[category['id']]  = categoryProducts

                    if (categoryProducts.length > 0) {
                        filteredCategories.push(category)
                    }
                }
                state.categories = filteredCategories
            } else {
                console.error('failed to fetch categories')
            }
        },
        // Assumeing it is already fetched
        loadCategoryProductList({state}) {
            if (!state.categories || state.categories.length == 0) {
                console.warn('no categories found')
                return
            }

            let categoryID = state.categories[state.selectedCategoryIndex]['id']
            if (state.cachedCategoryProducts[categoryID]) {
                state.categoryProducts = state.cachedCategoryProducts[categoryID]
                return
            }
        },
        setSelectedDate({state, dispatch}, date) {
            state.selectedDate = date
            state.availableDeliveryTimes = state.availaleDeliveryDateTimes[date]
            dispatch('setSelectedTime', state.availableDeliveryTimes[0])
        },
        async setSelectedTime({state, dispatch}, time) {
            const needToRefetchCategories = time != state.selectedTime
            state.selectedTime = time

            if (needToRefetchCategories) {
                await dispatch('getCategories')
                dispatch('checkIfCartProductsAreStillAvailable')
            }
        },
        async addItemToCart({dispatch, commit, state}, item) {
            commit('addItemToCart', item)
            await dispatch('checkCapacity')
        },
        async removeItemFromCart({dispatch, commit}, index) {
            commit('deleteItemInCart', index)
            await dispatch('checkCapacity')
        },
        async makeOrder({dispatch, state}, lang) {
            if (state.selfServiceToken === '') {
                console.warn('self service token is empty')
                return
            }
            
            if (state.cartItems.length == 0) {
                state.makeOrderErrorMessage = "Cart is empty"
                return
            }
            state.isProcessingOrder = true

            let formData = new FormData()
            formData.append('nempos', 1)
            formData.append(`cart[customer_name]`, state.username)
            formData.append(`cart[customer_email]`, state.userEmail)
            formData.append(`cart[customer_phone_no]`, state.userPhoneNumber)
            formData.append(`cart[customer_comment]`, state.userComment)
            formData.append(`cart[kartotek_signup]`, 0)
            formData.append(`cart[delivery_type]`, state.selectedDeliveryType)
            formData.append(`cart[delivery_type_asap]`, 0)
            formData.append(`cart[due_date]`, state.selectedDate)
            formData.append(`cart[due_time]`, state.selectedTime)
            formData.append(`cart[guest_language]`, lang)
            formData.append(`cart[loyalty_send]`, 1)

            if (state.selectedDeliveryType === 'delivery') {
                let address = ''
                if (state.addressClass === 'google_places_address_lookup') {
                    address = state.selectedDeliveryAddress.route + ' ' + state.selectedDeliveryAddress.street_number
                } else if (state.addressClass === 'dawa_address_lookup') {
                    address = state.selectedDeliveryAddress.forslagstekst.match(/^(.*)[\n]/);
                    address = address[1] ? address[1].replace(', ,', ',') : address;
                }

                formData.append('cart[customer_address]', address)
                formData.append('cart[address_id]', state.selectedDeliveryAddress.guestID)
                formData.append('cart[address_class]', state.addressClass)
            }

            for (const questionId in state.companyQuestionAnswers) {
                formData.append(`cart[company_questions][${questionId}][answer_text]`, state.companyQuestionAnswers[questionId])
            }

            for (const i in state.cartItems) {
                const cartItem = state.cartItems[i]
                formData.append(`cart[cart_items][${i}][type]`, 'product')
                formData.append(`cart[cart_items][${i}][product_id]`, cartItem.product.id)
                formData.append(`cart[cart_items][${i}][quantity]`, cartItem.quantity)

                for (const j in cartItem.formattedProductBuilder) {
                    for (const k in cartItem.formattedProductBuilder[j]) {
                        formData.append(`cart[cart_items][${i}][product_builder][${j}][${k}]`, cartItem.formattedProductBuilder[j][k])
                    }
                }


                let variantId = 0
                if (cartItem.variant) {
                    variantId = cartItem.variant.variant_id
                } 
                formData.append(`cart[cart_items][${i}][variant_id]`, variantId)

                for (let j in cartItem.ingredientToRemove) {
                    formData.append(`cart[cart_items][${i}][optout][${j}]`, cartItem.ingredientToRemove[j].id)
                }

                let extraIngredientsObject = {}
                for (let j in cartItem.extraIngredients) {
                    let ingredient = cartItem.extraIngredients[j]
                    extraIngredientsObject[ingredient.id] = ingredient.quantity
                    formData.append(`cart[cart_items][${i}][extra_ingredients][${ingredient.id}]`, ingredient.quantity)
                }
            }

            
            // for (var pair of formData.entries()) {
            //     console.log(pair[0]+ ' - ' + pair[1]); 
            // }
            // return


            let domainParam = `selfservice_token=${state.selfServiceToken}`
            let cmdParam = 'cmd=create'
            let url = `${process.env.VUE_APP_NEMTAKEAWAY_ENDPOINT}/api/v1/orders.php?${domainParam}&${cmdParam}`

            return new Promise( (resolve, reject) => {
                fetch(url, {
                    method: 'POST',
                    body: formData
                })
                .then(response => { return response.json() } )
                .then(responseData => {
                    Vue.prototype.logger.debug(responseData)
                    if (responseData.code.toLowerCase() === 'not_ok') {
                        state.makeOrderErrorMessage = responseData.message
                    } else {
                        state.orderData = {
                            secret: responseData.secret,
                            uuid: responseData.uuid
                        }
                        dispatch('resetNtaData')
                        dispatch('checkCapacity')
                        resolve(true)
                    }
                } )
                .catch(error => {
                    state.isProcessingOrder = false
                    reject(false)
                    console.error('failed to make order, ', error)
                } )
            } )
        },
        async checkOrderDetails({state}) {
            if (state.orderData.uuid === undefined || state.orderData.secret === undefined) {
                return
            }

            const domainParam = `selfservice_token=${state.selfServiceToken}`
            const cmdParam = 'cmd=get_order_details'
            const url = `${process.env.VUE_APP_NEMTAKEAWAY_ENDPOINT}/api/v1/orders.php?${domainParam}&${cmdParam}` + 
                        `&get_orderlines=1&get_payments=1&get_possible_actions=0` + 
                        `&uuid=${state.orderData.uuid}&secret=${state.orderData.secret}`

            let responseData = {}
            try {
                const response = await fetch(url)
                responseData = await response.json()
                Vue.prototype.logger.debug('check order details, response=', responseData)
            } catch(error) {
                state.isProcessingOrder = false
                return
            }

            // After makeOrder is called, at least checkOrderDetails will be called
            // once to get order data and show on ui. This is why isProcessingOrder is 
            // set to false here and not in makeOrder
            state.isProcessingOrder = false

            if (responseData.code.toLowerCase() === 'not_ok') {
                Vue.prototype.logger.debug('check order details not ok')
                state.completedOrderData = {}
            } else {
                state.completedOrderData = responseData.order[0]
            }
        },
        async validateDiscountCode({state}, discountCode) {
            const domainParam = `selfservice_token=${state.selfServiceToken}`
            const cmdParam = 'cmd=update_order_voucher'
            let url = `${process.env.VUE_APP_NEMTAKEAWAY_ENDPOINT}/api/v1/orders.php?${domainParam}&${cmdParam}`

            let formData = new FormData()
            formData.append('uuid', state.orderData.uuid)
            formData.append('voucher_code', discountCode)

            return new Promise( (resolve, reject) => {
                fetch(url, {
                    method: 'POST',
                    body: formData
                })
                .then(response => { return response.json() })
                .then(responseData => {
                    if (responseData.code.toLowerCase() === 'ok') {
                        state.didApplyDiscountCode = true
                        resolve({ success: true })
                    } else {
                        state.didApplyDiscountCode = false
                        resolve({ success: false, message: responseData.message })
                    }
                })
                .catch(error => { 
                    state.didApplyDiscountCode = false
                    reject(false)
                })
            } )
        },
        checkIfCartProductsAreStillAvailable({state}) {
            const uniqueProductIds = Object
                .entries(state.cachedCategoryProducts)
                .map(([key, value]) => new Set(value.map(item => item.id))) // Extract ids and create Sets
                .reduce((acc, set) => [...acc, ...set], []);

            for (const item of state.cartItems) {
                item.setAvailibility(uniqueProductIds.includes(item.product.id))
            }
        },
        async requestPaymentVia({state, getters}, requestType) {
            if (!state.orderData) {
                console.warn("something went wrong")
                return
            }

            return new Promise((resolve, reject) => {
                    let cmdParam = 'cmd=send_pay_request_to_customer'
                    let ntaAuthTokenParam = `ntaAuthToken=${getters.getRegisterSetting('nta_token')}`
                    let companyIdParam = `company_id=${getters.getRegisterSetting('nta_company_id')}`
                    let uuidParam = `uuid=${state.orderData.uuid}`
                    let secretParam = `secret=${state.orderData.secret}`
                    let requestTypeParam = `request_type=${requestType}`

                    let url = `${process.env.VUE_APP_NEMTAKEAWAY_ENDPOINT}/api/v1/orders.php?&${cmdParam}&${ntaAuthTokenParam}&${companyIdParam}&${uuidParam}&${secretParam}&${requestTypeParam}`

                    fetch(url)
                        .then(response => {
                            return response.json()
                        })
                        .then(data => {
                            if (data.code.toLowerCase() === 'not_ok') {
                                reject('error')
                            } else { 
                                if (requestType === 'sms') {
                                    state.didSendViaSms = true
                                } else if (requestType === 'email') {
                                    state.didSendViaEmail = true
                                }
                                resolve(true)
                            }
                        })
                        .catch(error => {
                            console.error(`failed to request payment via=${requestType}, error=${error}`)
                            reject('api error')
                        })
            })

        },
        resetNtaData({commit, dispatch, state}) {
            commit('resetNtaData')
            commit('resetDeliveryAddressData')
            dispatch('setSelectedDate', state.availaleDeliveryDates[0])
            state.selectedDeliveryType = state.deliveryTypes[0]
        },
        async startNewOrder({dispatch, state}) {
            dispatch('resetNtaData')
            state.orderData = false
            state.completedOrderData = false
            state.didApplyDiscountCode = false

            await dispatch('getSelfServiceToken')
            await dispatch('checkCapacity')
        },
        moveToNextDateTime({commit, dispatch, state}) {
            const currentIndex = state.availaleDeliveryDateTimes[state.selectedDate].indexOf(state.selectedTime);

            if (currentIndex == state.availaleDeliveryDateTimes[state.selectedDate].length - 1) {
                const dateIndex = state.availaleDeliveryDates.indexOf(state.selectedDate)
                const nextDate = state.availaleDeliveryDates[dateIndex + 1]
                dispatch('setSelectedDate', nextDate)
                dispatch('checkCapacity')
            } else {
                const nextTime = state.availaleDeliveryDateTimes[state.selectedDate][currentIndex + 1]
                dispatch('setSelectedTime', nextTime)
                dispatch('checkCapacity')
            }

        },
        moveToPreviousDateTime({commit, dispatch, state}) {
            const currentIndex = state.availaleDeliveryDateTimes[state.selectedDate].indexOf(state.selectedTime);
            if (currentIndex <= 0) {
                const dateIndex = state.availaleDeliveryDates.indexOf(state.selectedDate)
                if (dateIndex > 0) {
                    dispatch('setSelectedDate', state.availaleDeliveryDates[dateIndex - 1])
                    const lastAvailableTime = state.availableDeliveryTimes[state.availableDeliveryTimes.length - 1]
                    dispatch('setSelectedTime', lastAvailableTime)
                    dispatch('checkCapacity')
                }
            } else {
                const previousTime = state.availaleDeliveryDateTimes[state.selectedDate][currentIndex - 1]
                dispatch('setSelectedTime', previousTime)
                dispatch('checkCapacity')
            }
        },

        async populateDeliveryAddresses({commit, state}, query, searchDeliveryAddressByAccessAddress) {
            if (query.length < 2) {
                return
            }

            let postNumbers = ''
            try {
                postNumbers = state.deliveryPostNos.length ? '&postnr=' + state.deliveryPostNos.join('|') : '';
            } catch (error) {} 

            let extraQueryString = ''
            if (query.trim() !== query) {
                extraQueryString = '&startfra=adgangsadresse'
            }
            if (this.searchDeliveryAddressByAccessAddress) {
                extraQueryString += '&adgangsadresseid=' + searchDeliveryAddressByAccessAddress;
            }

            const queryParam = `q=${encodeURI(query)}`
            const constantParams = 'type=adresse&caretpos=3&supplerendebynavn=true&stormodtagerpostnumre=true&multilinje=true'
            const fuzzyParam = `fuzzy=${postNumbers}`
            const url = `https://api.dataforsyningen.dk/autocomplete?${queryParam}&${constantParams}&${fuzzyParam}${extraQueryString}`

            return new Promise( (resolve, reject) => {
                fetch(url)
                    .then(response => { return response.json() })
                    .then(responseData => {
                        state.deliveryEtaErrorMessage = false
                        resolve(responseData)
                    })
                    .catch(error => { reject(error) })
            } )
        },
        async getDeliveryETA({state}, deliveryAddress) {
            if (!deliveryAddress || deliveryAddress.guestID.length < 2) {
                return
            }
            state.isFetchingDeliveryETA = true

            const cmdParam = 'cmd=get_eta'
            const domainParam = `domain=${state.companyDomain}`
            const selfServiceTokenParam = `selfservice_token=${state.selfServiceToken}` 
            const guestAddressParam = `guest_address=${deliveryAddress.guestID}`
            const addressClassParam = `address_class=${state.addressClass}`
            const exprectedDueDateParam = `expected_due_date=${state.selectedDate}`
            const expectedDueTimeParam = `expected_due_time=${state.selectedTime}`

            let url = `${process.env.VUE_APP_NEMTAKEAWAY_ENDPOINT}/api/v1/delivery.php?${cmdParam}` + `&${selfServiceTokenParam}&${domainParam}&${guestAddressParam}&${addressClassParam}&${exprectedDueDateParam}&${expectedDueTimeParam}`

            let responseData = {}
            try {
                const response = await fetch(url)
                responseData = await response.json()

                if (!response.ok || responseData.code.toLowerCase() !== 'ok') {
                    state.isFetchingDeliveryETA = false
                    state.deliveryEtaData = false
                    state.deliveryEtaErrorMessage = responseData.message
                    return
                }
            } catch(error) {
                console.error('failed to get delivery nta, error=', error)
                state.isFetchingDeliveryETA = false
                return
            }

            state.deliveryEtaData = responseData
            state.deliveryEtaErrorMessage = false
            state.isFetchingDeliveryETA = false
        }
    }
}