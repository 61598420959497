import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import { loadLanguageAsync } from '../i18n-setup.js'
import store from '../store/index.js'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/SignedInApp.vue'),
    children: [
        {
            path: '/admin',
            name: 'admin',
            component: () => import('../views/signed_in/Admin.vue'),
            children: [
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: () => import('../views/signed_in/admin/Dashboard.vue')
                },
                {
                    path: 'accounts',
                    name: 'Accounts',
                    component: () => import('../views/signed_in/admin/Accounts.vue')
                },
                {
                    path: 'accounts/:uuid/edit',
                    name: 'Accounts.edit',
                    component: () => import('../components/admin/EditCreateAccount.vue'),
                    props: true
                },
                {
                    path: 'accounts/create',
                    name: 'Accounts.create',
                    component: () => import('../components/admin/EditCreateAccount.vue')
                },
                {
                    path: 'cashiers',
                    name: 'Cashiers',
                    component: () => import('../views/signed_in/admin/Cashiers.vue')
                },
                {
                    path: 'setup-company',
                    name: 'CompanySetup',
                    component: () => import('../views/signed_in/admin/CompanySetup.vue'),
                },
                {
                    path: 'customers',
                    name: 'Customers',
                    component: () => import('../views/signed_in/admin/Customers.vue')
                },
                {
                    path: 'customers/:uuid/edit',
                    name: 'Customers.edit',
                    component: () => import('../components/admin/EditCreateCustomer.vue'),
                    props: true
                },
                {
                    path: 'customers/create',
                    name: 'Customers.create',
                    component: () => import('../components/admin/EditCreateCustomer.vue')
                },
                {
                    path: 'integrations',
                    name: 'Integrations',
                    component: () => import('../views/signed_in/admin/Integrations.vue')
                },
                {
                    path: 'integrations/e-conomic/:uuid',
                    name: 'Integrations.e-conomic.edit',
                    component: () => import('../components/admin/integrations/edit/EditEconomicIntegration.vue')
                },
                {
                    path: 'integrations/nta/:uuid',
                    name: 'Integrations.nta.edit',
                    component: () => import('../components/admin/integrations/edit/EditNtaIntegration.vue')
                },
                {
                    path: 'integrations/wolt/:uuid',
                    name: 'Integrations.wolt.edit',
                    component: () => import('../components/admin/integrations/edit/EditWoltIntegration.vue')
                },
                {
                    path: 'integrations/:uuid/finalise',
                    name: 'Integrations.e-conomic.finalise',
                    component: () => import('../components/admin/FinaliseEconomicIntegration.vue')
                },
                {
                    path: 'modifier-groups',
                    name: 'ModifierGroups',
                    component: () => import('../views/signed_in/admin/ModifierGroups.vue')
                },
                {
                    path: 'modifier-groups/:uuid/edit',
                    name: 'ModifierGroups.edit',
                    component: () => import('../components/admin/EditCreateModifierGroup.vue')
                },
                {
                    path: 'modifier-groups/create',
                    name: 'ModifierGroups.create',
                    component: () => import('../components/admin/EditCreateModifierGroup.vue')
                },
                {
                    path: 'orders',
                    name: 'Orders',
                    component: () => import('../views/signed_in/admin/Orders.vue')
                },
                {
                    path: 'external_orders',
                    name: 'ExternalOrdersBackend',
                    component: () => import('../views/signed_in/admin/ExternalOrdersBackend.vue')
                },
                {
                    path: 'orders/:uuid',
                    name: 'Order',
                    component: () => import('../components/admin/Order.vue'),
                    props: true
                },
                {
                    path: 'orders/:uuid/simulate-production-prints',
                    name: 'SimulateProductionPrints',
                    component: () => import('../views/signed_in/superadmin/SimulateProductionPrints.vue'),
                },
                {
                    path: 'payment-types',
                    name: 'PaymentTypes',
                    component: () => import('../views/signed_in/admin/PaymentTypes.vue')
                },
                {
                    path: 'payment-types/:uuid/edit',
                    name: 'PaymentTypes.edit',
                    component: () => import('../components/admin/EditCreatePaymentType.vue')
                },
                {
                    path: 'payment-types/create',
                    name: 'PaymentTypes.create',
                    component: () => import('../components/admin/EditCreatePaymentType.vue')
                },
                {
                    path: 'price-profiles',
                    name: 'PriceProfiles',
                    component: () => import('../views/signed_in/admin/PriceProfiles.vue')
                },
                {
                    path: 'printers_company',
                    name: 'PrintersCompany',
                    component: () => import('../views/signed_in/admin/PrintersCompany.vue')
                },
                {
                    path: 'terminals_company',
                    name: 'TerminalsLocal',
                    component: () => import('../views/signed_in/admin/TerminalsList.vue')
                },
                {
                    path: 'boxes',
                    name: 'Boxes',
                    component: () => import('../views/signed_in/admin/Boxes.vue')
                },
                {
                    path: 'products',
                    name: 'Products',
                    component: () => import('../views/signed_in/admin/Products.vue')
                },
                {
                    path: 'bulk-create-products',
                    name: 'BulkCreateProducts',
                    component: () => import('../components/admin/BulkCreateProducts.vue')
                },
                {
                    path: 'products/:uuid/edit',
                    name: 'Products.edit',
                    component: () => import('../components/admin/EditCreateProduct.vue'),
                    props: true
                },
                {
                    path: 'products/:uuid/edit-prices',
                    name: 'ProductPrices.edit',
                    component: () => import('../components/admin/EditCreateProductPrices.vue'),
                    props: true
                },
                {
                    path: 'products/create',
                    name: 'Products.create',
                    component: () => import('../components/admin/EditCreateProduct.vue'),
                    props: true
                },
                {
                    path: 'product-csv-import',
                    name: 'Products.csv.import',
                    component: () => import('../components/admin/ProductCSVImport.vue'),
                },
                {
                    path: 'product-categories',
                    name: 'ProductCategories',
                    component: () => import('../views/signed_in/admin/ProductCategories.vue')
                },
                {
                    path: 'registers',
                    name: 'Registers',
                    component: () => import('../views/signed_in/admin/Registers.vue')
                },
                {
                    path: 'registers/:uuid/edit',
                    name: 'Registers.edit',
                    component: () => import('../components/admin/EditCreateRegister.vue'),
                    props: true
                },
                {
                    path: 'registers/create',
                    name: 'Registers.create',
                    component: () => import('../components/admin/EditCreateRegister.vue'),
                    props: true
                },
                {
                    path: 'registers/:uuid/journal',
                    name: 'Registers.journal',
                    component: () => import('../components/admin/RegisterJournal.vue'),
                    props: true
                },
                {
                    path: 'sales-areas',
                    name: 'SalesAreas',
                    component: () => import('../views/signed_in/admin/SalesAreas.vue')
                },
                {
                    path: 'sales-areas/:uuid/edit',
                    name: 'SalesAreas.edit',
                    component: () => import('../components/admin/EditSalesArea.vue'),
                },
                {
                    path: 'settings',
                    name: 'Settings',
                    component: () => import('../views/signed_in/admin/Settings.vue'),
                    children: [
                        {
                            path: 'general',
                            name: 'GeneralSettings',
                            component: () => import('../views/signed_in/admin/settings/GeneralSettings.vue'),
                        },
                        {
                            path: 'core-data',
                            name: 'EditCompany',
                            component: () => import('../views/signed_in/admin/EditCreateCompany.vue')
                        },
                        {
                            path: 'print-categories',
                            name: 'PrintCategories',
                            component: () => import('../views/signed_in/admin/settings/PrintCategories.vue')
                        },
                       
                        {
                            path: 'locations',
                            name: 'Locations',
                            component: () => import('../views/signed_in/admin/settings/Locations.vue'),
                        },
                        {
                            path: 'locations/create',
                            name: 'Locations.create',
                            component: () => import('../components/admin/EditCreateLocation.vue'),
                        },
                        {
                            path: 'locations/:uuid/edit',
                            name: 'Locations.edit',
                            component: () => import('../components/admin/EditCreateLocation.vue'),
                            props: true,
                        },
                        {
                            path: 'company-addons',
                            name: 'CompanyAddons',
                            component: () => import('../views/signed_in/admin/settings/CompanyAddons.vue'),
                        },

                    ]
                },
                {
                    path: 'statistics',
                    name: 'Statistics',
                    component: () => import('../views/signed_in/admin/Statistics.vue'),
                    children: [
                        {
                            path: 'products/units-sold-in-period',
                            name: 'TotalUnitsSoldOfEachProductInPeriod',
                            component: () => import('../components/admin/statistics/products/TotalUnitsSoldOfEachProductInPeriod.vue'),
                        },
                        {
                            path: 'products/units-sold-per-hour-in-period',
                            name: 'TotalUnitsSoldOfEachProductPerHourInPeriod',
                            component: () => import('../components/admin/statistics/products/TotalUnitsSoldOfEachProductPerHourInPeriod.vue'),
                        },
                        {
                            path: 'payments/payments-by-payment-type-in-period',
                            name: 'TotalPaymentsByPaymentTypeInPeriod',
                            component: () => import('../components/admin/statistics/payments/TotalPaymentsByPaymentTypeInPeriod.vue'),
                        },
                        {
                            path: 'turnover-in-period',
                            name: 'TurnoverInPeriod',
                            component: () => import('../components/admin/statistics/turnover/TurnoverInPeriod.vue'),
                        },
                        {
                            path: 'orders/cash-per-type',
                            name: 'CashPerType',
                            component: () => import('../components/admin/statistics/turnover/CashPerType.vue'),
                        },
                    ]
                },
                {
                    path: 'export/csv',
                    name: 'ExportCsv',
                    component: () => import('../views/signed_in/admin/ExportCsv.vue'),
                },
                {
                    path: 'export/saft',
                    name: 'ExportSaft',
                    component: () => import('../views/signed_in/admin/ExportSaft.vue'),
                },
                {
                    path: 'floor-plan',
                    name: 'EditFloorPlan',
                    component: () => import('../views/signed_in/admin/EditFloorPlan.vue')
                },
                {
                    path: 'time-delimitations',
                    name: 'TimeDelimitations',
                    component: () => import('../views/signed_in/admin/TimeDelimitations.vue')
                },
                {
                    path: 'users',
                    name: 'Users',
                    component: () => import('../views/signed_in/admin/Users.vue')
                },
                {
                    path: 'profile',
                    name: 'Profile.edit',
                    component: () => import('../components/admin/EditUser.vue')
                },
                {
                    path: 'users/:uuid/edit',
                    name: 'Users.edit',
                    component: () => import('../components/admin/EditUser.vue')
                },
                {
                    path: 'vat-types',
                    name: 'VatTypes',
                    component: () => import('../views/signed_in/admin/VatTypes.vue')
                },
                {
                    path: 'vat-types/:uuid/edit',
                    name: 'VatTypes.edit',
                    component: () => import('../components/admin/EditCreateVatType.vue')
                },
                {
                    path: 'vat-types/create',
                    name: 'VatTypes.create',
                    component: () => import('../components/admin/EditCreateVatType.vue')
                },
                {
                    path: 'opt-outs',
                    name: 'Opt-outs',
                    component: () => import('../views/signed_in/admin/OptOuts.vue')
                },
                {
                    path: 'opt-outs/:uuid/edit',
                    name: 'Opt-outs.edit',
                    component: () => import('../components/admin/EditCreateOptOut.vue')
                },
                {
                    path: 'opt-outs/create',
                    name: 'Opt-outs.create',
                    component: () => import('../components/admin/EditCreateOptOut.vue')
                },
                {
                    path: 'opt-outs/create-multiple',
                    name: 'Opt-outs.createMultiple',
                    component: () => import('../components/admin/CreateMultipleOptOut.vue')
                },
                {
                    path: 'opt-out-categories',
                    name: 'Opt-out-categories',
                    component: () => import('../views/signed_in/admin/OptOutCategories.vue')
                },
                {
                    path: 'opt-out-categories/:uuid/edit',
                    name: 'Opt-out-categories.edit',
                    component: () => import('../components/admin/EditCreateOptOutCategory.vue')
                },
                {
                    path: 'opt-out-categories/create',
                    name: 'Opt-out-categories.create',
                    component: () => import('../components/admin/EditCreateOptOutCategory.vue')
                },
                {
                    path: 'z-reports',
                    name: 'ZReports',
                    component: () => import('../views/signed_in/admin/ZReports.vue')
                },
                {
                    path: 'z-reports/:uuid',
                    name: 'ZReport',
                    component: () => import('../components/admin/ZReport.vue'),
                    props: true
                },
                {
                    path: 'z-reports/:uuid/transfers',
                    name: 'ZReportTransfers',
                    component: () => import('../components/admin/ZReportTransfers.vue'),
                    props: true,
                },
                {
                    path: 'forbidden',
                    name: 'Forbidden',
                    component: () => import('../components/admin/Forbidden.vue'),
                    props: true,
                },
                {
                    path: 'help',
                    name: 'Help',
                    component: () => import('../views/signed_in/admin/Help.vue')
                },
                {
                    path: 'right-to-be-forgotten',
                    name: 'RightToBeForgotten',
                    component: () => import('../views/signed_in/admin/RightToBeForgotten.vue')
                },
            ]
        },
        {
            path: '/superadmin',
            name: 'superadmin',
            component: () => import('../views/signed_in/Admin.vue'),
            children: [
                {
                    path: 'companies',
                    name: 'Companies',
                    component: () => import('../views/signed_in/superadmin/Companies.vue')
                },
                {
                    path: 'companies/create',
                    name: 'Companys.create', // OMG, haha... maybe improve pluralization solution in ResourceHeader ^^
                    component: () => import('../views/signed_in/admin/EditCreateCompany.vue')
                },
                {
                    path: 'companies/:uuid/edit',
                    name: 'Companys.edit', // OMG, haha... maybe improve pluralization solution in ResourceHeader ^^
                    component: () => import('../views/signed_in/admin/EditCreateCompany.vue')
                },
                {
                    path: 'companies/:uuid/copy-configuration',
                    name: 'Companys.copyConfiguration',
                    component: () => import('../views/signed_in/superadmin/CopyCompanyConfiguration.vue')
                },
                {
                    path: 'companies/:uuid/make-sub-of-new-enterprise',
                    name: 'Companys.makeSubOfNewEnterprise',
                    component: () => import('../views/signed_in/superadmin/MakeSubcompany.vue')
                },
                {
                    path: 'payments',
                    name: 'CompanyPayments',
                    component: () => import('../views/signed_in/superadmin/CompanyPayments.vue')
                },
                {
                    path: 'payment-sessions',
                    name: 'PaymentSessions',
                    component: () => import('../views/signed_in/superadmin/PaymentSessions.vue')
                },
                {
                    path: 'print-job',
                    name: 'PrintJobs',
                    component: () => import('../views/signed_in/superadmin/PrintJobs.vue')
                },
                {
                    path: 'printers',
                    name: 'Printers',
                    component: () => import('../views/signed_in/superadmin/Printers.vue')
                },
                {
                    path: 'server-logs',
                    name: 'ServerLogs',
                    component: () => import('../views/signed_in/superadmin/ServerLogs.vue')
                },
                {
                    path: 'misc',
                    name: 'Misc',
                    component: () => import('../views/signed_in/superadmin/Misc.vue')
                },
                {
                    path: 'pending-invitations',
                    name: 'AllInvitations',
                    component: () => import('../views/signed_in/superadmin/AllInvitations.vue')
                },
                {
                    path: 'addons',
                    name: 'Addons',
                    component: () => import('../views/signed_in/superadmin/Addons.vue')
                },
                {
                    path: 'companies-with-addons',
                    name: 'CompaniesWithAddons',
                    component: () => import('../views/signed_in/superadmin/CompaniesWithAddons.vue')
                },
                {
                    path: 'users',
                    name: 'AllUsers',
                    component: () => import('../views/signed_in/superadmin/AllUsers.vue')
                },
                {
                    path: 'flags',
                    name: 'Flags',
                    component: () => import('../views/signed_in/superadmin/Flags.vue')
                },
                {
                    path: 'mobile-pay-overview',
                    name: 'MobilePayOverview',
                    component: () => import('../views/signed_in/superadmin/MobilePayOverview.vue')
                },
                {
                    path: 'mobile-pay-stores',
                    name: 'MobilePayStores',
                    component: () => import('../views/signed_in/superadmin/MobilePayStores.vue')
                },
                {
                    path: 'mobile-pay-poses/:storeid',
                    name: 'MobilePayPoSes',
                    component: () => import('../views/signed_in/superadmin/MobilePayPoSes.vue')
                },
                {
                    path: 'Vippsmobilepaypos-msn',
                    name: 'VippsmobilepayposMSN',
                    component: () => import('../views/signed_in/superadmin/VippsmobilepayposMSN.vue')
                },
                {
                    path: 'vippsmobilepaypos-msus',
                    name: 'VippsmobilepayposMSUs',
                    component: () => import('../views/signed_in/superadmin/VippsmobilepayposMSUs.vue')
                },
                {
                    path: 'statisticsSuperadmin',
                    name: 'statisticsSuperadmin',
                    component: () => import('../views/signed_in/superadmin/StatisticsSuperadmin.vue'),
                },
                {
                    path: 'status-messages',
                    name: 'StatusMessages',
                    component: () => import('../views/signed_in/superadmin/StatusMessages.vue'),
                },
                {
                    path: 'status-messages/create',
                    name: 'StatusMessages.create',
                    component: () => import('../views/signed_in/superadmin/EditCreateStatusMessage.vue')
                },
                {
                    path: 'status-messages/:id/edit',
                    name: 'StatusMessages.edit',
                    component: () => import('../views/signed_in/superadmin/EditCreateStatusMessage.vue')
                },
            ]
        },
        {
            path: '/pos',
            name: 'PosRegisterSelect',
            component: () => import('../views/signed_in/PosRegisterSelect.vue')
        },
        {
            path: '/pos/:uuid',
            name: 'Pos',
            component: () => import('../views/signed_in/Pos.vue')
        },
    ]
  },
{
    path: '/maintenance',
    name: 'Maintenance',
    component: () => import('../views/Maintenance.vue')
},
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/sign-up',
    name: 'SignUp',
    component: () => import('../views/SignUp.vue')
  },
  {
    path: '/pos-auto-login',
    name: 'autoLogin',
    component: () => import('../views/signed_in/AutoLogin.vue')
  },
  
  {
    path: '/companies',
    name: 'Company',
    component: () => import('../views/signed_in/Company.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    let lang = localStorage.nemposLang;
    if (!lang) {
        lang = 'da';
    }

    // IMPTODO: Refactor evaluate authorization here (on both company subscription and user role levels), relying on route meta
    // and the resolution of a Promise for user (and company) data. This requires a good deal of refactorisation, but not too much.

    loadLanguageAsync(lang).then(() => next());

    store.commit('setTakeOverQueryNotActive');
});
// https://stackoverflow.com/a/47515250
if(window.navigator && navigator.serviceWorker) {
    //get registrations. returns promise
    navigator.serviceWorker.getRegistrations()
    .then(function(registrations) {
      for(let registration of registrations) {
        // unregister if there is a registration.
        registration.unregister();
      }
    });
  }
export default router
